import { IStrategy } from '@navix/utils/adapter'
import { Customer } from '../domain/customer.model'
import { GetCustomerAddressesResponse } from '../domain/get-customer-addresses.response'

export class FromGetCustomerAddressResponse
  implements IStrategy<GetCustomerAddressesResponse, Customer>
{
  convert(response: GetCustomerAddressesResponse): Customer {
    return {
      addresses: response.value.map(
        address =>
          <Customer['addresses'][number]>{
            name: address.AddressName,
            address1: address.Address1,
            address2: address.Address2,
            country: address.Country,
            city: address.City,
            state: address.State,
            zip: address.Zip,
            isPrimary: address.IsPrimary,
            isActive: address.IsActive,
            typeDescription: address.CustomerLocationTypeDescription,
            typeId: address.CustomerLocationTypeId,
            id: address.CustomerLocationId,
            codes: address.LocationCodes.map(code => ({
              code: code.Code,
              isPrimary: code.IsPrimary,
              id: code.TenantCustomerLocationCodeId
            }))
          }
      )
    } as Customer
  }
}
