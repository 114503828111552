import { IStrategy } from '@navix/utils/adapter'
import { CustomerForm } from '../domain/customer-form.model'
import { UpdateCustomerRequest } from '../domain/update-customer.request'

export class ToUpdateCustomerRequest
  implements IStrategy<CustomerForm, UpdateCustomerRequest> {
  convert(customer: CustomerForm): UpdateCustomerRequest {
    return {
      customerId: customer.id,
      accountNumbers: customer.accountNumbers?.map(accountNumber => ({
        accountNumber: accountNumber.name,
        isPrimary: accountNumber.isPrimary as boolean,
        tenantCustomerAccountNumberId: accountNumber.id
      })),
      billingTermId: customer.details.billingTermId,
      customerName: customer.details.name,
      currencyId: customer.details.currencyId as number,
      externalId: customer.details.externalId,
      startDate: customer.details.startDate?.toISOString(),
      endDate: customer.details.endDate?.toISOString(),
      hasSmc3: customer.details.hasSmc3,
    }
  }
}
