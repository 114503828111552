import { makeEnvironmentProviders } from '@angular/core'
import { provideState } from '@ngrx/store'
import {
  CUSTOMERS_FEATURE_KEY,
  customersReducer
} from '../+state/customers.reducer'
import { CustomersEffects } from '../+state/customers.effects'
import { provideEffects } from '@ngrx/effects'
import { CustomersFacade } from '../+state/customers.facade'

export function provideCustomersFeature() {
  return makeEnvironmentProviders([
    provideEffects(CustomersEffects),
    provideState(CUSTOMERS_FEATURE_KEY, customersReducer),
    CustomersFacade
  ])
}
