import { HttpClient } from '@angular/common/http'
import { Injectable, inject } from '@angular/core'
import { Observable } from 'rxjs'

import { OdataParser, QUERY_PARSER } from '@navix/utils/query-parser'
import {
  API_URL,
  API_URL_V1_1_VERSION,
  HEADER_API_KEY
} from '@navix/utils/tokens'
import { AddCustomerAddressRequest } from '../domain/add-customer-address.request'
import { AddCustomerContactRequest } from '../domain/add-customer-contact.request'
import { AddCustomerFeeRequest } from '../domain/add-customer-fee.request'
import { AddCustomerRequest } from '../domain/add-customer.request'
import { DataFilters } from '../domain/data-filters.model'
import { GetCustomerAddressTypesResponse } from '../domain/get-customer-address-types.response'
import { GetCustomerAddressesResponse } from '../domain/get-customer-addresses.response'
import { GetCustomerContactsResponse } from '../domain/get-customer-contacts.response'
import { GetCustomerDetailsResponse } from '../domain/get-customer-details.response'
import { GetCustomerFeesResponse } from '../domain/get-customer-fees.response'
import { GetCustomersResponse } from '../domain/get-customers.response'
import { UpdateCustomerAddressRequest } from '../domain/update-customer-address.request'
import { UpdateCustomerContactRequest } from '../domain/update-customer-contact.request'
import { UpdateCustomerFeeRequest } from '../domain/update-customer-fee.request'
import { UpdateCustomerRequest } from '../domain/update-customer.request'

@Injectable({
  providedIn: 'root'
})
export class CustomersService {
  private readonly _http = inject(HttpClient)
  private readonly apiUrl = inject(API_URL)
  private readonly apiV1_1Url = inject(API_URL_V1_1_VERSION)
  private readonly apiKey = inject(HEADER_API_KEY)
  private readonly parser = inject<OdataParser>(QUERY_PARSER)

  private readonly apiCustomersUrl = `${this.apiUrl}customers/` as const
  private readonly apiCustomersNewUrl = `${this.apiV1_1Url}customers/` as const
  private readonly headersWithApiKey: { [key: string]: string } = {
    ...this.apiKey
  } as { [key: string]: string }

  private filterColumns = ['Description', 'Address1', 'City', 'State', 'Zip']

  getCustomers(filters: DataFilters): Observable<GetCustomersResponse> {
    const query = this.parser
      .prepare('customer')
      .setCount(true)
      .setContainFilterColumns(this.filterColumns)

    if (filters.search) query.setContainFilter(filters.search)
    if (filters.itemsPerPage) query.setTop(filters.itemsPerPage)
    if (filters.itemsPerPage && filters.page)
      query.setSkip(filters.itemsPerPage * (filters.page - 1))
    if (filters.sortBy && filters.sortDirection)
      query.setOrderBy({
        column: filters.sortBy,
        direction: filters.sortDirection
      })
    if (filters.uuids && filters.uuids.length)
      query.setInPropertyFilterColumns('Uuid', filters.uuids)

    return this._http.get<GetCustomersResponse>(
      `${this.apiCustomersNewUrl}list`,
      {
        params: query.getQueryParamsAsHttpParams(),
        headers: this.headersWithApiKey
      }
    )
  }

  getCustomerAddressTypes(): Observable<GetCustomerAddressTypesResponse> {
    return this._http.get<GetCustomerAddressTypesResponse>(
      `${this.apiCustomersUrl}customer-location-types`,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  addCustomer(request: AddCustomerRequest): Observable<void> {
    return this._http.post<void>(
      `${this.apiCustomersNewUrl}customers`,
      request,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  getCustomerDetails(
    customerId: number
  ): Observable<GetCustomerDetailsResponse> {
    return this._http.get<GetCustomerDetailsResponse>(
      `${this.apiCustomersNewUrl}${customerId}/detail`,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  getCustomerContacts(
    customerId: number,
    filters?: DataFilters
  ): Observable<GetCustomerContactsResponse> {
    const query = this.parser.prepare('CustomerContacts').setCount(true)

    if (filters?.itemsPerPage) query.setTop(filters?.itemsPerPage)
    if (filters?.itemsPerPage && filters?.page)
      query.setSkip(filters?.itemsPerPage * (filters?.page - 1))

    if (filters?.sortBy && filters.sortDirection) {
      const persistentSort = {
        column: 'isPrimary' as const,
        direction: 'desc' as const
      }
      const filterSortBy = {
        column: filters.sortBy as string,
        direction: filters.sortDirection
      }
      query.setMultipleOrderBy(
        persistentSort.column === filterSortBy.column
          ? [filterSortBy]
          : [persistentSort, filterSortBy]
      )
    }

    return this._http.get<GetCustomerContactsResponse>(
      `${this.apiCustomersNewUrl}${customerId}/customer-contacts`,
      {
        params: query.getQueryParamsAsHttpParams(),
        headers: this.headersWithApiKey
      }
    )
  }

  getCustomerAddresses(
    customerId: number,
    filters?: DataFilters
  ): Observable<GetCustomerAddressesResponse> {
    const query = this.parser.prepare('CustomerLocations').setCount(true)

    if (filters?.itemsPerPage) query.setTop(filters?.itemsPerPage)
    if (filters?.itemsPerPage && filters?.page)
      query.setSkip(filters?.itemsPerPage * (filters?.page - 1))

    if (filters?.sortBy && filters.sortDirection) {
      const persistentSort = {
        column: 'isPrimary' as const,
        direction: 'desc' as const
      }
      const filterSortBy = {
        column: filters.sortBy as string,
        direction: filters.sortDirection
      }
      query.setMultipleOrderBy(
        persistentSort.column === filterSortBy.column
          ? [filterSortBy]
          : [persistentSort, filterSortBy]
      )
    }

    if (filters?.isActiveAddress) {
      query.setEqualityFilter('IsActive', filters.isActiveAddress)
    }
    return this._http.get<GetCustomerAddressesResponse>(
      `${this.apiCustomersNewUrl}${customerId}/customer-locations`,
      {
        params: query.getQueryParamsAsHttpParams(),
        headers: this.headersWithApiKey
      }
    )
  }

  updateCustomer(request: UpdateCustomerRequest) {
    return this._http.put<void>(
      `${this.apiCustomersNewUrl}customers/update-details`,
      request,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  addCustomerContact(request: AddCustomerContactRequest) {
    return this._http.post<void>(
      `${this.apiCustomersNewUrl}customer-contacts`,
      request,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  addCustomerAddress(request: AddCustomerAddressRequest) {
    return this._http.post<void>(
      `${this.apiCustomersNewUrl}customer-locations`,
      request,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  updateCustomerAddress(request: UpdateCustomerAddressRequest) {
    return this._http.put<void>(
      `${this.apiCustomersNewUrl}customer-locations`,
      request,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  updateCustomerContact(request: UpdateCustomerContactRequest) {
    return this._http.put<void>(
      `${this.apiCustomersNewUrl}customer-contacts`,
      request,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  deleteCustomerContact(customerContactId: number) {
    return this._http.delete<void>(
      `${this.apiCustomersNewUrl}${customerContactId}/delete`,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  updateCustomerAddressActiveStatus(addressId: number, active: boolean) {
    return this._http.post<void>(
      `${this.apiCustomersNewUrl}${addressId}/activation`,
      active,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  getCustomerFees(customerId: number) {
    return this._http.get<GetCustomerFeesResponse>(
      `${this.apiCustomersNewUrl}fees`,
      {
        params: { customerId },
        headers: this.headersWithApiKey
      }
    )
  }

  addtCustomerFees(customerFee: AddCustomerFeeRequest) {
    return this._http.post<void>(
      `${this.apiCustomersNewUrl}fees`,
      customerFee,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  updateCustomerFees(customerFee: UpdateCustomerFeeRequest) {
    return this._http.put<void>(`${this.apiCustomersNewUrl}fees`, customerFee, {
      headers: this.headersWithApiKey
    })
  }
}
