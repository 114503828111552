import { createActionGroup, emptyProps, props } from '@ngrx/store'
import { Customer } from '../domain/customer.model'
import { AsyncOperations } from '../domain/customers-loading.model'
import { DataFilters } from '../domain/data-filters.model'
import { CustomerForm } from '../domain/customer-form.model'
import { AddressType } from '../domain/address-type.model'
import { CustomerFee } from '../domain/customer-fee.model'

export const customersActions = createActionGroup({
  source: 'Customers',
  events: {
    //#region General customers
    'set selected customer': props<{ customerId: number }>(),
    'load customers': props<{ filters: DataFilters }>(),
    'load customers success': props<{ customers: Customer[] }>(),
    'load customers fail': props<{ error: unknown }>(),

    'set total count': props<{ count: number }>(),
    'set list filters': props<{ filters: DataFilters }>(),
    'patch list filters': props<{ filters: DataFilters }>(),
    'reset list filters': emptyProps(),

    'load customer details': props<{ customerId: number }>(),
    'load customer details success': props<{ customer: Customer }>(),

    'add customer': props<{ customer: CustomerForm }>(),
    'add customer success': emptyProps(),
    'add customer fail': props<{ error: string }>(),

    'update customer': props<{
      customer: Pick<CustomerForm, 'accountNumbers' | 'id' | 'details'>
    }>(),
    'update customer success': props<{
      customer: Pick<CustomerForm, 'accountNumbers' | 'id' | 'details'>
    }>(),
    'update customer fail': props<{ error: string }>(),

    'update customer account numbers': props<{
      customer: Pick<CustomerForm, 'accountNumbers' | 'id' | 'details'>
    }>(),
    'update customer account numbers success': props<{
      customer: Pick<CustomerForm, 'accountNumbers' | 'id' | 'details'>
    }>(),
    'update customer account numbers fail': props<{ error: string }>(),

    'set loading': props<{ operation: AsyncOperations; loading: boolean }>(),
    //#endregion

    //#region Customer Address
    'set addesses list filters': props<{ addressesFilters: DataFilters }>(),
    'reset addesses list filters': emptyProps(),
    'update customer address active status': props<{
      addressId: number
      active: boolean
    }>(),
    'update customer address active status success': props<{
      customerWithUpdatedAddress: Customer
    }>(),
    'update customer address active status fail': props<{ error: unknown }>(),

    'load customer address types': emptyProps(),
    'load customer address types success': props<{
      addressTypes: AddressType[]
    }>(),
    'load customer address types fail': props<{ error: unknown }>(),

    'load customer addresses': props<{
      customerId: number
      filters: DataFilters
    }>(),
    'load customer addresses success': props<{
      customerWithAddresses: Customer
    }>(),

    'add customer address': props<{
      customer: Pick<CustomerForm, 'address' | 'id'>
    }>(),
    'add customer address success': props<{
      customer: Pick<CustomerForm, 'address' | 'id'>
    }>(),
    'add customer address fail': props<{ error: string }>(),

    'update customer address': props<{
      customer: Pick<CustomerForm, 'address' | 'id'>
    }>(),
    'update customer address success': props<{
      customer: Pick<CustomerForm, 'address' | 'id'>
    }>(),
    'update customer address fail': props<{ error: string }>(),
    //#endregion

    //#region Customer Contact
    'set contact list filters': props<{ contactFilters: DataFilters }>(),
    'reset contact list filters': emptyProps(),

    'add customer contact': props<{
      customer: Pick<CustomerForm, 'contact' | 'id'>
    }>(),
    'add customer contact success': props<{
      customer: Pick<CustomerForm, 'contact' | 'id'>
    }>(),
    'add customer contact fail': props<{ error: string }>(),

    'update customer contact': props<{
      customer: Pick<CustomerForm, 'contact' | 'id'>
    }>(),
    'update customer contact success': props<{
      customer: Pick<CustomerForm, 'contact' | 'id'>
    }>(),
    'update customer contact fail': props<{ error: string }>(),

    'load customer contacts': props<{
      customerId: number
      filters: DataFilters
    }>(),
    'load customer contacts success': props<{
      customerWithContacts: Customer
    }>(),

    'delete customer contact': props<{
      customerContactId: number
      customerId: number
    }>(),
    'delete customer contact success': props<{ customerId: number }>(),
    //#endregion

    //#region Fees
    'load customer fees': props<{ customerId: number }>(),
    'load customer fees success': props<{
      fees: CustomerFee[]
      customerId: number
    }>(),

    'add customer fee': props<{
      customerFee: Pick<CustomerForm, 'fee' | 'id'>
    }>(),
    'add customer fee success': props<{
      customerFee: Pick<CustomerForm, 'fee' | 'id'>
    }>(),
    'add customer fee fail': props<{ error: string }>(),

    'update customer fee': props<{
      customerFee: Pick<CustomerForm, 'fee' | 'id'>
    }>(),
    'update customer fee success': props<{
      customerFee: Pick<CustomerForm, 'fee' | 'id'>
    }>(),
    'update customer fee fail': props<{ error: string }>(),

    'set markups list filters': props<{ markupsFilters: DataFilters }>(),
    'set non routed fees list filters': props<{
      nonRoutedFeesFilters: DataFilters
    }>(),
    'reset markups list filters': emptyProps(),
    'reset non routed fees list filters': emptyProps()

    //#endregion
  }
})
