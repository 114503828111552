import { Injectable, inject } from '@angular/core'
import { select, Store } from '@ngrx/store'

import { customersActions } from './customers.actions'
import * as CustomersSelectors from './customers.selectors'

import { filter, firstValueFrom, map } from 'rxjs'
import { AsyncOperations } from '../domain/customers-loading.model'
import { DataFilters } from '../domain/data-filters.model'
import { CustomerForm } from '../domain/customer-form.model'
import { CustomerFee } from '../domain/customer-fee.model'

@Injectable()
export class CustomersFacade {
  private readonly store: Store = inject(Store)

  allCustomers$ = this.store.pipe(select(CustomersSelectors.selectAllCustomers))

  allCustomerAddressTypes$ = this.store.pipe(
    select(CustomersSelectors.selectAddressTypes),
    filter(types => types.length > 0)
  )

  selectedCustomerId$ = this.store.pipe(
    select(CustomersSelectors.selectSelectedId)
  )
  selectedCustomer$ = this.store.pipe(select(CustomersSelectors.selectEntity))
  totalCount$ = this.store.pipe(select(CustomersSelectors.selectTotalCount))
  accountNumberStatuses$ = this.store.pipe(
    select(CustomersSelectors.selectAccountNumberStatuses)
  )

  loading$ = this.store.pipe(select(CustomersSelectors.selectLoading))
  loading = this.store.selectSignal(CustomersSelectors.selectLoading)

  filters$ = this.store.pipe(select(CustomersSelectors.selecFilters))
  contactFilters$ = this.store.pipe(
    select(CustomersSelectors.selectContactListFilters)
  )
  addressFilters$ = this.store.pipe(
    select(CustomersSelectors.selectAddressesListFilters)
  )

  markupsFilters$ = this.store.pipe(
    select(CustomersSelectors.selectMarkupsListFilters),
    map(
      filters =>
        filters as Required<Pick<DataFilters, 'sortBy' | 'sortDirection'>>
    )
  )
  nonRoutedFeesListFilters$ = this.store.pipe(
    select(CustomersSelectors.selectNonRoutedFeesListFilters),
    map(
      filters =>
        filters as Required<Pick<DataFilters, 'sortBy' | 'sortDirection'>>
    )
  )

  customerMarkups$ = this.selectedCustomer$.pipe(
    map(customer => customer?.fees),
    filter(fees => fees != undefined),
    map(fees => fees as CustomerFee[]),
    map(fees => fees.filter(fee => !fee.isNonRouted))
  )

  customerNonRoutedFees$ = this.selectedCustomer$.pipe(
    map(customer => customer?.fees),
    filter(fees => !!fees),
    map(fees => fees as CustomerFee[]),
    map(fees => fees.filter(fee => fee.isNonRouted))
  )

  loadCustomers(filters: DataFilters) {
    this.store.dispatch(customersActions.loadCustomers({ filters }))
  }

  loadCustomersAddressTypes() {
    this.store.dispatch(customersActions.loadCustomerAddressTypes())
  }

  selectCustomer(customerId: number) {
    this.store.dispatch(customersActions.loadCustomerDetails({ customerId }))
  }

  async loadCustomerContacts(filters: DataFilters) {
    const customerId = (await firstValueFrom(
      this.selectedCustomerId$
    )) as number

    this.store.dispatch(
      customersActions.loadCustomerContacts({ customerId, filters })
    )
  }

  async loadCustomerAddresses(filters: DataFilters) {
    const customerId = (await firstValueFrom(
      this.selectedCustomerId$
    )) as number
    this.store.dispatch(
      customersActions.loadCustomerAddresses({ customerId, filters })
    )
  }
  startLoading(operation: AsyncOperations) {
    this.store.dispatch(
      customersActions.setLoading({
        operation,
        loading: true
      })
    )
  }
  endLoading(operation: AsyncOperations) {
    this.store.dispatch(
      customersActions.setLoading({
        operation,
        loading: false
      })
    )
  }

  addCustomer(newCustomer: CustomerForm) {
    this.store.dispatch(customersActions.addCustomer({ customer: newCustomer }))
  }

  updateCustomer(customer: CustomerForm) {
    this.store.dispatch(customersActions.updateCustomer({ customer }))
  }

  updateCustomerAccountNumbers(customer: CustomerForm) {
    this.store.dispatch(
      customersActions.updateCustomerAccountNumbers({ customer })
    )
  }

  async updateCustomerContact(contact: CustomerForm['contact']) {
    const customerId = await firstValueFrom(this.selectedCustomerId$)

    this.store.dispatch(
      customersActions.updateCustomerContact({
        customer: {
          contact,
          id: customerId as number
        }
      })
    )
  }

  async updateCustomerAddress(address: CustomerForm['address']) {
    const customerId = await firstValueFrom(this.selectedCustomerId$)

    this.store.dispatch(
      customersActions.updateCustomerAddress({
        customer: {
          address,
          id: customerId as number
        }
      })
    )
  }

  async addCustomerContact(contact: CustomerForm['contact']) {
    const customerId = await firstValueFrom(this.selectedCustomerId$)
    this.store.dispatch(
      customersActions.addCustomerContact({
        customer: { contact: contact, id: customerId as number }
      })
    )
  }

  async addCustomerAddress(address: CustomerForm['address']) {
    const customerId = await firstValueFrom(this.selectedCustomerId$)
    this.store.dispatch(
      customersActions.addCustomerAddress({
        customer: { address, id: customerId as number }
      })
    )
  }

  async deleteCustomerContact(customerContactId: number) {
    const customerId = await firstValueFrom(this.selectedCustomerId$)
    this.store.dispatch(
      customersActions.deleteCustomerContact({
        customerContactId,
        customerId: customerId as number
      })
    )
  }

  updateAddressActiveStatus(addressId: number, active: boolean) {
    this.store.dispatch(
      customersActions.updateCustomerAddressActiveStatus({ addressId, active })
    )
  }

  async loadCustomerFees() {
    const customerId = (await firstValueFrom(
      this.selectedCustomerId$
    )) as number
    this.store.dispatch(customersActions.loadCustomerFees({ customerId }))
  }

  loadCustomerFeesByCustomerId(customerId: number) {
    this.store.dispatch(customersActions.loadCustomerFees({ customerId }))
    this.store.dispatch(customersActions.setSelectedCustomer({ customerId }))
  }

  async addCustomerFeeMarkup(customerFee: CustomerForm['fee']) {
    const customerId = Number(await firstValueFrom(this.selectedCustomerId$))

    this.store.dispatch(
      customersActions.addCustomerFee({
        customerFee: {
          fee: { ...customerFee, isNonRouted: false },
          id: customerId
        }
      })
    )
  }

  async addCustomerFeeNonRouted(customerFee: CustomerForm['fee']) {
    const customerId = Number(await firstValueFrom(this.selectedCustomerId$))

    this.store.dispatch(
      customersActions.addCustomerFee({
        customerFee: {
          fee: { ...customerFee, isNonRouted: true },
          id: customerId
        }
      })
    )
  }

  async editCustomerFeeMarkup(customerFee: CustomerForm['fee']) {
    const customerId = Number(await firstValueFrom(this.selectedCustomerId$))

    this.store.dispatch(
      customersActions.updateCustomerFee({
        customerFee: {
          fee: { ...customerFee, isNonRouted: false },
          id: customerId
        }
      })
    )
  }

  async editCustomerFeeNonRouted(customerFee: CustomerForm['fee']) {
    const customerId = Number(await firstValueFrom(this.selectedCustomerId$))

    this.store.dispatch(
      customersActions.updateCustomerFee({
        customerFee: {
          fee: { ...customerFee, isNonRouted: true },
          id: customerId
        }
      })
    )
  }

  async updateListFilters(filters: DataFilters) {
    const currentFilters = await firstValueFrom(this.filters$)
    const newFilters = { ...currentFilters, ...(filters ?? {}) }
    this.store.dispatch(
      customersActions.setListFilters({ filters: newFilters })
    )
  }

  async updateAddressListFilters(filters: DataFilters) {
    const currentFilters = await firstValueFrom(this.addressFilters$)
    const newFilters = { ...currentFilters, ...(filters ?? {}) }
    this.store.dispatch(
      customersActions.setAddessesListFilters({ addressesFilters: newFilters })
    )
  }

  async updateContactListFilters(filters: DataFilters) {
    const currentFilters = await firstValueFrom(this.contactFilters$)
    const newFilters = { ...currentFilters, ...(filters ?? {}) }
    this.store.dispatch(
      customersActions.setContactListFilters({ contactFilters: newFilters })
    )
  }

  async updateNonRoutedFeesListFilters(filters: DataFilters) {
    const currentFilters = await firstValueFrom(this.nonRoutedFeesListFilters$)
    const newFilters = { ...currentFilters, ...(filters ?? {}) }
    this.store.dispatch(
      customersActions.setNonRoutedFeesListFilters({
        nonRoutedFeesFilters: newFilters
      })
    )
  }

  async updateMarkupListFilters(filters: DataFilters) {
    const currentFilters = await firstValueFrom(this.markupsFilters$)
    const newFilters = { ...currentFilters, ...(filters ?? {}) }
    this.store.dispatch(
      customersActions.setMarkupsListFilters({ markupsFilters: newFilters })
    )
  }

  resetAddressListFilters() {
    this.store.dispatch(customersActions.resetAddessesListFilters())
  }
  resetContactListFilters() {
    this.store.dispatch(customersActions.resetContactListFilters())
  }

  resetMarkupListFilters() {
    this.store.dispatch(customersActions.resetMarkupsListFilters())
  }

  resetNonRoutedFeesListFilters() {
    this.store.dispatch(customersActions.resetNonRoutedFeesListFilters())
  }

  patchListFilters(filters: DataFilters) {
    this.store.dispatch(customersActions.patchListFilters({ filters }))
  }

  resetFilters() {
    this.store.dispatch(customersActions.resetListFilters())
  }
}
