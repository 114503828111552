import { IStrategy } from '@navix/utils/adapter'
import { UpdateCustomerContactRequest } from '../domain/update-customer-contact.request'
import { CustomerForm } from '../domain/customer-form.model'

export class ToUpdateCustomerContactRequest
  implements IStrategy<CustomerForm, UpdateCustomerContactRequest>
{
  convert(customer: CustomerForm): UpdateCustomerContactRequest {
    return {
      contactName: customer.contact.name,
      department: customer.contact.department,
      contactPhone: customer.contact.phone,
      contactEmail: customer.contact.email,
      isPrimary: customer.contact.isPrimary ?? false,
      customerId: customer.id,
      customerContactId: customer.contact.id as number
    }
  }
}
