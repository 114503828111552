import { IStrategy } from '@navix/utils/adapter'
import { CustomerForm } from '../domain/customer-form.model'
import { AddCustomerFeeRequest } from '../domain/add-customer-fee.request'

export class ToAddCustomerFeeRequest
  implements IStrategy<CustomerForm, AddCustomerFeeRequest>
{
  convert(customer: CustomerForm): AddCustomerFeeRequest {
    return {
      serviceType: customer.fee.serviceTypeId as number,
      markup: customer.fee.markup.percentage as number,
      minimum: customer.fee.markup.minimum as number,
      maximum: customer.fee.markup.maximum,
      startDate: customer.fee.dateRange.startDate as string,
      endDate: customer.fee.dateRange.endDate as string,
      default: customer.fee.isDefault ?? false,
      isNonRouted: customer.fee.isNonRouted,
      transportationServiceTypeId: customer.fee.serviceTypeId as number,
      customerId: customer.id
    }
  }
}
