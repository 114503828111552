import { Dialog } from '@angular/cdk/dialog'
import { Injectable, inject } from '@angular/core'
import { LegacyAlertsFacade } from '@navix/alerts/domain'
import { MiscellaneousFacade } from '@navix/miscellaneous/domain'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import {
  catchError,
  exhaustMap,
  finalize,
  map,
  of,
  switchMap,
  tap,
  withLatestFrom
} from 'rxjs'
import { CustomerAdapter } from '../adapter/CustomerAdapter'
import { FromGetCustomerAddressResponse } from '../adapter/FromGetCustomerAddressesResponse'
import { FromGetCustomerContactsResponse } from '../adapter/FromGetCustomerContactsResponse'
import { FromGetCustomerDetailsResponse } from '../adapter/FromGetCustomerDetailsResponse'
import { FromGetCustomerFeesResponse } from '../adapter/FromGetCustomerFeesResponse'
import { FromGetCustomersResponse } from '../adapter/FromGetCustomersResponse'
import { ToAddCustomerAddressRequest } from '../adapter/ToAddCustomerAddressRequest'
import { ToAddCustomerContactRequest } from '../adapter/ToAddCustomerContactRequest'
import { ToAddCustomerFeeRequest } from '../adapter/ToAddCustomerFeeRequest'
import { ToAddCustomerRequest } from '../adapter/ToAddCustomerRequest'
import { ToUpdateCustomerAddressRequest } from '../adapter/ToUpdateCustomerAddressRequest'
import { ToUpdateCustomerContactRequest } from '../adapter/ToUpdateCustomerContactRequest'
import { ToUpdateCustomerFeeRequest } from '../adapter/ToUpdateCustomerFeeRequest'
import { ToUpdateCustomerRequest } from '../adapter/ToUpdateCustomerRequest'
import { AddressType } from '../domain/address-type.model'
import { Customer } from '../domain/customer.model'
import { AsyncOperations } from '../domain/customers-loading.model'
import { CustomersService } from '../infrastructure/customers.service'
import { customersActions } from './customers.actions'
import {
  ADD_CUSTOMER_ADDRESS_DIALOG_ID,
  ADD_CUSTOMER_CONTACT_DIALOG_ID,
  ADD_CUSTOMER_DIALOG_ID,
  ADD_CUSTOMER_FEE_DIALOG_ID,
  DELETE_CUSTOMER_CONTACT_DIALOG_ID,
  EDIT_CUSTOMER_ACCOUNT_NUMBERS_DIALOG_ID,
  EDIT_CUSTOMER_ADDRESS_DIALOG_ID,
  EDIT_CUSTOMER_CONTACT_DIALOG_ID,
  EDIT_CUSTOMER_DIALOG_ID,
  EDIT_CUSTOMER_FEE_DIALOG_ID
} from './customers.constants'
import { CustomersFacade } from './customers.facade'

@Injectable()
export class CustomersEffects {
  private alertsFacade = inject(LegacyAlertsFacade)
  private customersFacade = inject(CustomersFacade)
  private miscellaneousFacade = inject(MiscellaneousFacade)
  private customersService = inject(CustomersService)
  private actions$ = inject(Actions)

  private dialog = inject(Dialog)

  private customerAdapter = new CustomerAdapter()

  constructor() {
    this.customerAdapter.set(FromGetCustomersResponse)
    this.customerAdapter.set(FromGetCustomerDetailsResponse)
    this.customerAdapter.set(FromGetCustomerContactsResponse)
    this.customerAdapter.set(FromGetCustomerAddressResponse)
    this.customerAdapter.set(ToAddCustomerRequest)
    this.customerAdapter.set(ToUpdateCustomerRequest)
    this.customerAdapter.set(ToAddCustomerContactRequest)
    this.customerAdapter.set(ToAddCustomerAddressRequest)
    this.customerAdapter.set(ToUpdateCustomerContactRequest)
    this.customerAdapter.set(ToUpdateCustomerAddressRequest)
    this.customerAdapter.set(FromGetCustomerFeesResponse)
    this.customerAdapter.set(ToAddCustomerFeeRequest)
    this.customerAdapter.set(ToUpdateCustomerFeeRequest)
  }

  loadCustomers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(customersActions.loadCustomers),
      tap(() => this.customersFacade.startLoading(AsyncOperations.getAll)),

      exhaustMap(({ filters }) =>
        this.customersService.getCustomers(filters).pipe(
          map(response => ({
            customers: this.customerAdapter.convert(
              FromGetCustomersResponse,
              response
            ),
            totalCount: response['@odata.count']
          })),

          switchMap(response => [
            customersActions.loadCustomersSuccess({
              customers: response.customers
            }),
            customersActions.setTotalCount({ count: response.totalCount })
          ]),
          catchError(error =>
            of(customersActions.loadCustomersFail({ error }))
          ),
          finalize(() =>
            this.customersFacade.endLoading(AsyncOperations.getAll)
          )
        )
      )
    )
  )

  loadCustomersFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(customersActions.loadCustomersFail),
        tap(error => {
          console.log(error)
          this.alertsFacade.addAlert({
            alertType: 'danger',
            label: "There's an error trying to load customers."
          })
        })
      ),
    { dispatch: false }
  )

  loadCustomerAddressTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(customersActions.loadCustomerAddressTypes),
      switchMap(() => this.customersService.getCustomerAddressTypes()),
      map(response =>
        response.map(
          type =>
            <AddressType>{
              id: type.customerLocationTypeId,
              description: type.description
            }
        )
      ),
      map(addressTypes =>
        customersActions.loadCustomerAddressTypesSuccess({ addressTypes })
      )
    )
  )

  //#region Add Customer
  addCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(customersActions.addCustomer),
      tap(() => this.customersFacade.startLoading(AsyncOperations.add)),
      map(({ customer }) =>
        this.customerAdapter.convert(ToAddCustomerRequest, customer)
      ),
      exhaustMap(request =>
        this.customersService.addCustomer(request).pipe(
          map(() => customersActions.addCustomerSuccess()),
          catchError(error => of(customersActions.addCustomerFail({ error }))),
          finalize(() => this.customersFacade.endLoading(AsyncOperations.add))
        )
      )
    )
  )

  addCustomerOnSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(customersActions.addCustomerSuccess),
      tap(() => {
        this.alertsFacade.addAlert({
          label: `Customer has been added successfully.`,
          alertType: 'success'
        })
      }),
      tap(() => this.dialog.getDialogById(ADD_CUSTOMER_DIALOG_ID)?.close()),
      withLatestFrom(this.customersFacade.filters$),
      switchMap(([, filters]) =>
        of(customersActions.loadCustomers({ filters }))
      )
    )
  )

  addCustomerOnError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(customersActions.addCustomerFail),
        tap(() => {
          this.alertsFacade.addAlert({
            alertType: 'danger',
            label: "There's an error trying to create customer."
          })
        })
      ),
    { dispatch: false }
  )
  //#endregion

  //#region load details

  loadCustomerDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(customersActions.loadCustomerDetails),
      tap(() => this.customersFacade.startLoading(AsyncOperations.getDetails)),
      exhaustMap(({ customerId }) =>
        this.customersService.getCustomerDetails(customerId).pipe(
          map(details =>
            this.customerAdapter.convert(
              FromGetCustomerDetailsResponse,
              details
            )
          ),
          map(customer => ({ ...customer, id: customerId })),
          map(customer =>
            customersActions.loadCustomerDetailsSuccess({ customer })
          ),
          tap(() => this.customersFacade.endLoading(AsyncOperations.getDetails))
        )
      )
    )
  )

  loadCustomerContacts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(customersActions.loadCustomerContacts),
      tap(() => this.customersFacade.startLoading(AsyncOperations.getContacts)),
      exhaustMap(({ customerId, filters }) =>
        this.customersService.getCustomerContacts(customerId, filters).pipe(
          map(contacts => ({
            contactsCount: contacts['@odata.count'],
            contacts: this.customerAdapter.convert(
              FromGetCustomerContactsResponse,
              contacts
            ).contacts,
            id: customerId
          })),

          map(customer => customer as Customer),
          map(customer =>
            customersActions.loadCustomerContactsSuccess({
              customerWithContacts: customer
            })
          ),
          tap(() =>
            this.customersFacade.endLoading(AsyncOperations.getContacts)
          )
        )
      )
    )
  )

  loadCustomerAddresses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(customersActions.loadCustomerAddresses),
      tap(() =>
        this.customersFacade.startLoading(AsyncOperations.getAddresses)
      ),
      exhaustMap(({ customerId, filters }) =>
        this.customersService.getCustomerAddresses(customerId, filters).pipe(
          map(addresses => ({
            addressesCount: addresses['@odata.count'],
            addresses: this.customerAdapter.convert(
              FromGetCustomerAddressResponse,
              addresses
            ).addresses,
            id: customerId
          })),

          map(customer => customer as Customer),
          map(customer =>
            customersActions.loadCustomerAddressesSuccess({
              customerWithAddresses: customer
            })
          ),
          tap(() =>
            this.customersFacade.endLoading(AsyncOperations.getAddresses)
          )
        )
      )
    )
  )
  //#endregion

  //#region Update Customer

  updateCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(customersActions.updateCustomer),
      tap(() =>
        this.customersFacade.startLoading(AsyncOperations.updateCustomer)
      ),
      map(({ customer }) => ({
        request: this.customerAdapter.convert(
          ToUpdateCustomerRequest,
          customer
        ),
        customer
      })),
      exhaustMap(({ request, customer }) =>
        this.customersService.updateCustomer(request).pipe(
          map(() => customersActions.updateCustomerSuccess({ customer })),
          catchError(error =>
            of(customersActions.updateCustomerFail({ error }))
          ),
          tap(() =>
            this.customersFacade.endLoading(AsyncOperations.updateCustomer)
          )
        )
      )
    )
  )

  updateCustomerOnSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(customersActions.updateCustomerSuccess),
      tap(() => {
        this.alertsFacade.addAlert({
          label: `Customer has been updated successfully.`,
          alertType: 'success'
        })
      }),
      tap(() => this.dialog.getDialogById(EDIT_CUSTOMER_DIALOG_ID)?.close()),
      switchMap(({ customer }) =>
        of(customersActions.loadCustomerDetails({ customerId: customer.id }))
      )
    )
  )

  updateCustomerOnError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(customersActions.updateCustomerFail),
        tap(() => {
          this.alertsFacade.addAlert({
            alertType: 'danger',
            label: "There's an error trying to update customer."
          })
        })
      ),
    { dispatch: false }
  )
  //#endregion

  //#region Update Customer Account numbers

  updateCustomerAccountNumbers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(customersActions.updateCustomerAccountNumbers),
      tap(() =>
        this.customersFacade.startLoading(
          AsyncOperations.updateCustomerAccountNumbers
        )
      ),
      map(({ customer }) => ({
        request: this.customerAdapter.convert(
          ToUpdateCustomerRequest,
          customer
        ),
        customer
      })),
      exhaustMap(({ request, customer }) =>
        this.customersService.updateCustomer(request).pipe(
          map(() =>
            customersActions.updateCustomerAccountNumbersSuccess({ customer })
          ),
          catchError(error =>
            of(customersActions.updateCustomerAccountNumbersFail({ error }))
          ),
          tap(() =>
            this.customersFacade.endLoading(
              AsyncOperations.updateCustomerAccountNumbers
            )
          )
        )
      )
    )
  )

  updateCustomerAccountNumbersOnSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(customersActions.updateCustomerAccountNumbersSuccess),
      tap(() => {
        this.alertsFacade.addAlert({
          label: `Customer account numbers has been updated successfully.`,
          alertType: 'success'
        })
      }),
      tap(() =>
        this.dialog
          .getDialogById(EDIT_CUSTOMER_ACCOUNT_NUMBERS_DIALOG_ID)
          ?.close()
      ),
      switchMap(({ customer }) =>
        of(customersActions.loadCustomerDetails({ customerId: customer.id }))
      )
    )
  )

  updateCustomerAccountNumbersOnError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(customersActions.updateCustomerAccountNumbersFail),
        tap(() => {
          this.alertsFacade.addAlert({
            alertType: 'danger',
            label: "There's an error trying to update customer account numbers."
          })
        })
      ),
    { dispatch: false }
  )
  //#endregion

  //#region add customer contact

  addCustomerContact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(customersActions.addCustomerContact),
      tap(() => this.customersFacade.startLoading(AsyncOperations.addContact)),
      map(({ customer }) => ({
        request: this.customerAdapter.convert(
          ToAddCustomerContactRequest,
          customer
        ),
        customer
      })),
      exhaustMap(({ request, customer }) =>
        this.customersService.addCustomerContact(request).pipe(
          map(() => customersActions.addCustomerContactSuccess({ customer })),
          catchError(error =>
            of(customersActions.addCustomerContactFail({ error }))
          ),
          tap(() => this.customersFacade.endLoading(AsyncOperations.addContact))
        )
      )
    )
  )

  addCustomerContactOnSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(customersActions.addCustomerContactSuccess),
      withLatestFrom(this.customersFacade.contactFilters$),

      tap(() => {
        this.alertsFacade.addAlert({
          label: `Customer contact has been added successfully.`,
          alertType: 'success'
        })
      }),
      tap(() =>
        this.dialog.getDialogById(ADD_CUSTOMER_CONTACT_DIALOG_ID)?.close()
      ),
      switchMap(([{ customer }, filters]) =>
        of(
          customersActions.loadCustomerContacts({
            filters,
            customerId: customer.id
          })
        )
      )
    )
  )

  addCustomerContactOnError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(customersActions.addCustomerContactFail),
        tap(() => {
          this.alertsFacade.addAlert({
            alertType: 'danger',
            label: "There's an error trying to add customer contact."
          })
        })
      ),
    { dispatch: false }
  )
  //#endregion

  //#region add customer address

  addCustomerAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(customersActions.addCustomerAddress),
      tap(() => this.customersFacade.startLoading(AsyncOperations.addAddress)),
      map(({ customer }) => ({
        request: this.customerAdapter.convert(
          ToAddCustomerAddressRequest,
          customer
        ),
        customer
      })),
      exhaustMap(({ request, customer }) =>
        this.customersService.addCustomerAddress(request).pipe(
          map(() => customersActions.addCustomerAddressSuccess({ customer })),
          catchError(httpError =>
            of(
              customersActions.addCustomerAddressFail({
                error: httpError.error
              })
            )
          ),
          tap(() => this.customersFacade.endLoading(AsyncOperations.addAddress))
        )
      )
    )
  )

  addCustomerAddressOnSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(customersActions.addCustomerAddressSuccess),
      withLatestFrom(this.customersFacade.addressFilters$),

      tap(() => {
        this.alertsFacade.addAlert({
          label: `Customer address has been added successfully.`,
          alertType: 'success'
        })
      }),
      tap(() =>
        this.dialog.getDialogById(ADD_CUSTOMER_ADDRESS_DIALOG_ID)?.close()
      ),
      switchMap(([{ customer }, filters]) =>
        of(
          customersActions.loadCustomerAddresses({
            filters,
            customerId: customer.id
          })
        )
      )
    )
  )

  addCustomerAddressOnError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(customersActions.addCustomerAddressFail),
        map(({ error }) =>
          error?.includes('Primary already exists')
            ? 'A default address already exists for this customer.'
            : "There's an error trying to add the address."
        ),
        tap(label => {
          this.alertsFacade.addAlert({
            alertType: 'danger',
            label
          })
        })
      ),
    { dispatch: false }
  )
  //#endregion

  //#region Update Customer Contact

  updateCustomerContact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(customersActions.updateCustomerContact),
      tap(() =>
        this.customersFacade.startLoading(AsyncOperations.updateCustomerContact)
      ),
      map(({ customer }) => ({
        request: this.customerAdapter.convert(
          ToUpdateCustomerContactRequest,
          customer
        ),
        customer
      })),
      exhaustMap(({ request, customer }) =>
        this.customersService.updateCustomerContact(request).pipe(
          map(() =>
            customersActions.updateCustomerContactSuccess({ customer })
          ),
          catchError(httpError =>
            of(
              customersActions.updateCustomerContactFail({
                error: httpError.error
              })
            )
          ),
          tap(() =>
            this.customersFacade.endLoading(
              AsyncOperations.updateCustomerContact
            )
          )
        )
      )
    )
  )

  updateCustomerContactOnSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(customersActions.updateCustomerContactSuccess),
      withLatestFrom(this.customersFacade.contactFilters$),
      tap(() => {
        this.alertsFacade.addAlert({
          label: `Customer contact has been updated successfully.`,
          alertType: 'success'
        })
      }),
      tap(() =>
        this.dialog.getDialogById(EDIT_CUSTOMER_CONTACT_DIALOG_ID)?.close()
      ),
      switchMap(([{ customer }, filters]) =>
        of(
          customersActions.loadCustomerContacts({
            customerId: customer.id,
            filters
          })
        )
      )
    )
  )

  updateCustomerContactOnError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(customersActions.updateCustomerContactFail),
        map(({ error }) =>
          error?.includes('Primary already exists')
            ? 'A default contact already exists for this customer.'
            : "There's an error trying to update the customer contact."
        ),
        tap(label => {
          this.alertsFacade.addAlert({
            alertType: 'danger',
            label
          })
        })
      ),
    { dispatch: false }
  )
  //#endregion

  //#region delete Customer Contact

  deleteCustomerContact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(customersActions.deleteCustomerContact),
      tap(() =>
        this.customersFacade.startLoading(AsyncOperations.deleteCustomerContact)
      ),
      exhaustMap(({ customerContactId, customerId }) =>
        this.customersService.deleteCustomerContact(customerContactId).pipe(
          map(() =>
            customersActions.deleteCustomerContactSuccess({ customerId })
          ),
          tap(() =>
            this.customersFacade.endLoading(
              AsyncOperations.updateCustomerContact
            )
          )
        )
      )
    )
  )

  deleteCustomerContactOnSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(customersActions.deleteCustomerContactSuccess),
      withLatestFrom(this.customersFacade.contactFilters$),
      tap(() => {
        this.alertsFacade.addAlert({
          label: `Customer contact has been deleted successfully.`,
          alertType: 'success'
        })
      }),
      tap(() =>
        this.dialog.getDialogById(DELETE_CUSTOMER_CONTACT_DIALOG_ID)?.close()
      ),
      switchMap(([{ customerId }, filters]) =>
        of(
          customersActions.loadCustomerContacts({
            customerId,
            filters
          })
        )
      )
    )
  )
  //#endregion

  //#region Update Customer Address

  updateCustomerAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(customersActions.updateCustomerAddress),
      tap(() =>
        this.customersFacade.startLoading(AsyncOperations.updateCustomerAddress)
      ),
      map(({ customer }) => ({
        request: this.customerAdapter.convert(
          ToUpdateCustomerAddressRequest,
          customer
        ),
        customer
      })),
      exhaustMap(({ request, customer }) =>
        this.customersService.updateCustomerAddress(request).pipe(
          map(() =>
            customersActions.updateCustomerAddressSuccess({ customer })
          ),
          catchError(httpError =>
            of(
              customersActions.updateCustomerAddressFail({
                error: httpError.error.message
              })
            )
          ),
          tap(() =>
            this.customersFacade.endLoading(
              AsyncOperations.updateCustomerAddress
            )
          )
        )
      )
    )
  )

  updateCustomerAddressOnSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(customersActions.updateCustomerAddressSuccess),
      withLatestFrom(this.customersFacade.addressFilters$),

      tap(() => {
        this.alertsFacade.addAlert({
          label: `Customer address has been updated successfully.`,
          alertType: 'success'
        })
      }),
      tap(() =>
        this.dialog.getDialogById(EDIT_CUSTOMER_ADDRESS_DIALOG_ID)?.close()
      ),
      switchMap(([{ customer }, filters]) =>
        of(
          customersActions.loadCustomerAddresses({
            filters,
            customerId: customer.id
          })
        )
      )
    )
  )

  updateCustomerAddressOnError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(customersActions.updateCustomerAddressFail),
        map(({ error }) => error),
        tap(label => {
          this.alertsFacade.addAlert({
            alertType: 'danger',
            label
          })
        })
      ),
    { dispatch: false }
  )
  //#endregion

  //#region Update Customer Address Active Status

  updateCustomerAddressActiveStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(customersActions.updateCustomerAddressActiveStatus),
      withLatestFrom(this.customersFacade.selectedCustomer$),
      exhaustMap(([payload, customer]) =>
        this.customersService
          .updateCustomerAddressActiveStatus(payload.addressId, payload.active)
          .pipe(
            map(() => {
              const isActive = (address: Customer['addresses'][number]) =>
                address.id === payload.addressId
                  ? payload.active
                  : address.isActive
              return customersActions.updateCustomerAddressActiveStatusSuccess({
                customerWithUpdatedAddress: {
                  ...(customer as Customer),
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  addresses: customer!.addresses.map(address => ({
                    ...address,
                    isActive: isActive(address)
                  }))
                }
              })
            }),
            catchError(error =>
              of(
                customersActions.updateCustomerAddressActiveStatusFail({
                  error
                })
              )
            )
          )
      )
    )
  )

  updateCustomerAddressActiveStatusOnError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(customersActions.updateCustomerAddressActiveStatusFail),
        tap(() => {
          this.alertsFacade.addAlert({
            alertType: 'danger',
            label:
              "There's an error trying to update customer address active status."
          })
        })
      ),
    { dispatch: false }
  )
  //#endregion

  //#region Customer Fees

  loadCustomerFees$ = createEffect(() =>
    this.actions$.pipe(
      ofType(customersActions.loadCustomerFees),
      exhaustMap(({ customerId }) =>
        this.customersService.getCustomerFees(customerId).pipe(
          map(response =>
            this.customerAdapter.convert(FromGetCustomerFeesResponse, response)
          ),
          map(fees =>
            customersActions.loadCustomerFeesSuccess({ fees, customerId })
          )
        )
      )
    )
  )

  addCustomerFee$ = createEffect(() =>
    this.actions$.pipe(
      ofType(customersActions.addCustomerFee),
      tap(() => this.customersFacade.startLoading(AsyncOperations.addFee)),
      map(({ customerFee }) => ({
        request: this.customerAdapter.convert(
          ToAddCustomerFeeRequest,
          customerFee
        ),
        customerFee
      })),
      exhaustMap(({ customerFee, request }) =>
        this.customersService.addtCustomerFees(request).pipe(
          map(() => customersActions.addCustomerFeeSuccess({ customerFee })),
          catchError(error =>
            of(customersActions.addCustomerFeeFail({ error }))
          ),
          tap(() => this.customersFacade.endLoading(AsyncOperations.addFee))
        )
      )
    )
  )

  addCustomerFeeOnSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(customersActions.addCustomerFeeSuccess),

      tap(() => {
        this.alertsFacade.addAlert({
          label: `Customer fee has been added successfully.`,
          alertType: 'success'
        })
      }),
      tap(() => this.dialog.getDialogById(ADD_CUSTOMER_FEE_DIALOG_ID)?.close()),
      switchMap(({ customerFee }) =>
        of(
          customersActions.loadCustomerFees({
            customerId: customerFee.id
          })
        )
      )
    )
  )

  addCustomerFeeOnError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(customersActions.addCustomerFeeFail),
        tap(() => {
          this.alertsFacade.addAlert({
            alertType: 'danger',
            label: "There's an error trying to add customer fee."
          })
        })
      ),
    { dispatch: false }
  )

  updateCustomerFee$ = createEffect(() =>
    this.actions$.pipe(
      ofType(customersActions.updateCustomerFee),
      tap(() => this.customersFacade.startLoading(AsyncOperations.updateFee)),
      map(({ customerFee }) => ({
        request: this.customerAdapter.convert(
          ToUpdateCustomerFeeRequest,
          customerFee
        ),
        customerFee
      })),
      exhaustMap(({ customerFee, request }) =>
        this.customersService.updateCustomerFees(request).pipe(
          map(() => customersActions.updateCustomerFeeSuccess({ customerFee })),
          catchError(error =>
            of(customersActions.updateCustomerFeeFail({ error }))
          ),
          tap(() => this.customersFacade.endLoading(AsyncOperations.updateFee))
        )
      )
    )
  )

  updateCustomerFeeOnSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(customersActions.updateCustomerFeeSuccess),

      tap(() => {
        this.alertsFacade.addAlert({
          label: `Customer fee has been updateed successfully.`,
          alertType: 'success'
        })
      }),
      tap(() =>
        this.dialog.getDialogById(EDIT_CUSTOMER_FEE_DIALOG_ID)?.close()
      ),
      switchMap(({ customerFee }) =>
        of(
          customersActions.loadCustomerFees({
            customerId: customerFee.id
          })
        )
      )
    )
  )

  updateCustomerFeeOnError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(customersActions.updateCustomerFeeFail),
        tap(() => {
          this.alertsFacade.addAlert({
            alertType: 'danger',
            label: "There's an error trying to update customer fee."
          })
        })
      ),
    { dispatch: false }
  )

  //#endregion
}
