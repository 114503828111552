import { IStrategy } from '@navix/utils/adapter'
import { AddCustomerRequest } from '../domain/add-customer.request'
import { CustomerForm } from '../domain/customer-form.model'

export class ToAddCustomerRequest
  implements IStrategy<CustomerForm, AddCustomerRequest> {
  convert(customer: CustomerForm): AddCustomerRequest {
    const request: AddCustomerRequest = {
      description: customer.details.name,
      addressName: customer.address.name,
      address1: customer.address.address1,
      address2: customer.address.address2,
      city: customer.address.city,
      state: customer.address.stateCode as string,
      zip: customer.address.zip,
      country: customer.address.countryCode as string,
      principalContact: {
        contactName: customer.contact.name,
        department: customer.contact.department,
        contactPhone: customer.contact.phone,
        contactEmail: customer.contact.email
      },
      currencyId: customer.details.currencyId as number,
      billingTermId: customer.details.billingTermId as number,
      externalId: customer.details.externalId,
      startDate: customer.details.startDate?.toISOString(),
      endDate: customer.details.endDate?.toISOString(),
      hasSmc3: customer.details.hasSmc3
    }

    if (
      Object.values(request.principalContact ?? {}).every(
        v => v === '' || v === undefined || v === null
      )
    )
      delete request.principalContact

    return request
  }
}
