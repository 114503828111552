export const CUSTOMERS_ABSOLUTE_ROUTE_PREFIX =
  '/tenant-admin/customer/s' as const
export const ADD_CUSTOMER_DIALOG_ID = 'ADD_CUSTOMER_DIALOG_ID' as const
export const EDIT_CUSTOMER_DIALOG_ID = 'EDIT_CUSTOMER_DIALOG_ID' as const
export const ADD_CUSTOMER_ADDRESS_DIALOG_ID =
  'ADD_CUSTOMER_ADDRESS_DIALOG_ID' as const
export const ADD_CUSTOMER_CONTACT_DIALOG_ID =
  'ADD_CUSTOMER_CONTACT_DIALOG_ID' as const
export const EDIT_CUSTOMER_ADDRESS_DIALOG_ID =
  'EDIT_CUSTOMER_ADDRESS_DIALOG_ID' as const
export const EDIT_CUSTOMER_CONTACT_DIALOG_ID =
  'EDIT_CUSTOMER_CONTACT_DIALOG_ID' as const
export const DELETE_CUSTOMER_CONTACT_DIALOG_ID =
  'DELETE_CUSTOMER_CONTACT_DIALOG_ID' as const
export const ADD_CUSTOMER_FEE_DIALOG_ID = 'ADD_CUSTOMER_FEE_DIALOG_ID' as const
export const EDIT_CUSTOMER_FEE_DIALOG_ID =
  'EDIT_CUSTOMER_FEE_DIALOG_ID' as const
export const EDIT_CUSTOMER_ACCOUNT_NUMBERS_DIALOG_ID =
  'EDIT_CUSTOMER_ACCOUNT_NUMBERS_DIALOG_ID' as const
