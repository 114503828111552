import { IStrategy } from '@navix/utils/adapter'

import { Customer } from '../domain/customer.model'
import { GetCustomerDetailsResponse } from '../domain/get-customer-details.response'

export class FromGetCustomerDetailsResponse
  implements IStrategy<GetCustomerDetailsResponse, Customer> {
  convert(response: GetCustomerDetailsResponse): Customer {
    return {
      name: response.description,
      accountNumbers: <Customer['accountNumbers']>(
        response.customerAccountNumbers.map(x => ({
          accountNumber: x.accountNumber,
          isPrimary: x.isPrimary,
          id: x.customerAccountNumberId
        }))
      ),
      withDetails: true,
      currencyId: response.currencyId,
      currencyDescription: response.currencyDescription,
      billingTermId: response.billingTermId,
      billingTermDescription: response.billingTermDescription,
      externalId: response.externalId,
      startDate: isNaN(Date.parse(response.startDate ?? ''))
        ? undefined
        : new Date(response.startDate ?? ''),
      endDate: isNaN(Date.parse(response.endDate ?? ''))
        ? undefined
        : new Date(response.endDate ?? ''),
      hasSmc3: response.hasSmc3,
      uuid: response.uuid,
    } as Customer
  }
}
