import { IStrategy } from '@navix/utils/adapter'

import { CustomerForm } from '../domain/customer-form.model'
import { AddCustomerAddressRequest } from '../domain/add-customer-address.request'

export class ToAddCustomerAddressRequest
  implements IStrategy<CustomerForm, AddCustomerAddressRequest>
{
  convert(customer: CustomerForm): AddCustomerAddressRequest {
    return {
      address1: customer.address.address1,
      address2: customer.address.address2,
      addressName: customer.address.name,
      city: customer.address.city,
      country: customer.address.countryCode as string,
      isPrimary: customer.address.isPrimary ?? false,
      state: customer.address.stateCode as string,
      zip: customer.address.zip,
      locationTypeId: customer.address.typeId?.toString() ?? '',
      customerId: customer.id,
      customerLocationTypeId: customer.address.typeId?.toString() ?? '',
      locationCodes: customer.address.codes.map(code => ({
        code: code.code,
        isPrimary: code.isPrimary ?? false,
        tenantCustomerLocationCodeId: code.id
      }))
    }
  }
}
