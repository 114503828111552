import { IStrategy } from '@navix/utils/adapter'
import { GetCustomersResponse } from '../domain/get-customers.response'
import { Customer } from '../domain/customer.model'

export class FromGetCustomersResponse
  implements IStrategy<GetCustomersResponse, Customer[]>
{
  convert(response: GetCustomersResponse): Customer[] {
    const customers = response.value.map(customer => {
      const hasDefaultContact =
        !!customer.ContactName && !!customer.ContactEmail
      const defaultContact: Customer['contacts'][number] = {
        email: customer.ContactEmail,
        isPrimary: true,
        name: customer.ContactName
      }
      return <Customer>{
        id: customer.CustomerId,
        uuid: customer.Uuid,
        withDetails: false,
        name: customer.Description,
        externalId: customer.ExternalId,
        address: {
          address1: customer.Address1,
          address2: customer.Address2,
          country: customer.Country,
          city: customer.City,
          state: customer.State,
          zip: customer.Zip,
          zip4: customer.Zip4
        },

        addresses: [
          {
            address1: customer.Address1,
            address2: customer.Address2,
            country: customer.Country,
            city: customer.City,
            state: customer.State,
            zip: customer.Zip,
            zip4: customer.Zip4,
            isPrimary: true
          }
        ],

        contacts: hasDefaultContact ? [defaultContact] : [],
        primaryAccountNumber: customer.PrimaryAccountNumber,
        startDate:
          customer.StartDate === null
            ? undefined
            : new Intl.DateTimeFormat('en-US').format(
                Date.parse(customer.StartDate!)
              ),
        endDate:
          customer.EndDate === null
            ? undefined
            : new Intl.DateTimeFormat('en-US').format(
                Date.parse(customer.EndDate!)
              )
      }
    })
    return customers
  }
}
