import { AdapterBase } from '@navix/utils/adapter'
import { Customer } from '../domain/customer.model'
import { CustomerForm } from '../domain/customer-form.model'

export class ToCustomerAdapter extends AdapterBase<
  unknown,
  Customer | Customer[]
> {}
export class FromCustomerAdapter extends AdapterBase<
  Customer | CustomerForm,
  unknown
> {}

export class CustomerAdapter extends AdapterBase<unknown, unknown> {}
