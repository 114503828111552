import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity'
import { createReducer, on, Action } from '@ngrx/store'

import { customersActions } from './customers.actions'
import { Customer } from '../domain/customer.model'
import { CustomersLoading } from '../domain/customers-loading.model'
import { DataFilters } from '../domain/data-filters.model'
import { AccountNumberStatus } from '../domain/account-number.model'
import { AddressType } from '../domain/address-type.model'

export const CUSTOMERS_FEATURE_KEY = 'feature-customers'

export interface CustomersState extends EntityState<Customer> {
  selectedId?: string | number
  totalCount: number
  loading: CustomersLoading
  filters: DataFilters
  contactFilters: DataFilters
  addressesFilters: DataFilters
  markupsFilters: DataFilters
  nonRoutedFeesFilters: DataFilters
  addressTypes: AddressType[]
  accountNumberStatuses: AccountNumberStatus[]
}

export interface CustomersPartialState {
  readonly [CUSTOMERS_FEATURE_KEY]: CustomersState
}

export const customersAdapter: EntityAdapter<Customer> =
  createEntityAdapter<Customer>()

export const initialCustomersState: CustomersState =
  customersAdapter.getInitialState({
    totalCount: 0,
    loading: {
      getAll: false,
      add: false,
      getDetails: false,
      getContacts: false,
      getAddresses: false,
      updateCustomer: false,
      addContact: false,
      addAddress: false,
      updateCustomerAddress: false,
      updateCustomerContact: false,
      deleteCustomerContact: false,
      updateCustomerAccountNumbers: false,
      getCustomerFees: false,
      addFee: false,
      updateFee: false,
      getRatingEngineInfo: false,
      updateRatingEngineInfo: false
    },
    filters: {
      search: undefined,
      page: 1,
      itemsPerPage: 25,
      sortBy: 'Description',
      sortDirection: 'asc'
    },
    contactFilters: {
      page: 1,
      itemsPerPage: 10,
      sortBy: 'ContactName',
      sortDirection: 'asc'
    },
    addressesFilters: {
      page: 1,
      itemsPerPage: 10,
      sortBy: 'isPrimary',
      sortDirection: 'desc',
      isActive: false
    },
    addressTypes: [],
    accountNumberStatuses: [
      { isPrimary: true, description: 'Primary' },
      { isPrimary: false, description: 'Secondary' }
    ],
    markupsFilters: {
      sortBy: 'transportationServiceTypeDescription',
      sortDirection: 'asc'
    },
    nonRoutedFeesFilters: {
      sortBy: 'transportationServiceTypeDescription',
      sortDirection: 'asc'
    }
  })

const reducer = createReducer(
  initialCustomersState,
  on(
    customersActions.loadCustomersSuccess,
    (state, { customers }): CustomersState =>
      customersAdapter.setAll(customers, state)
  ),
  on(
    customersActions.loadCustomerDetails,
    (state, { customerId }): CustomersState => ({
      ...state,
      selectedId: customerId
    })
  ),
  on(
    customersActions.setSelectedCustomer,
    (state, { customerId }): CustomersState => ({
      ...state,
      selectedId: customerId
    })
  ),
  on(
    customersActions.loadCustomerDetailsSuccess,
    (state, { customer }): CustomersState =>
      customersAdapter.upsertOne(customer, state)
  ),
  on(
    customersActions.loadCustomerContactsSuccess,
    (state, { customerWithContacts }): CustomersState =>
      customersAdapter.upsertOne(customerWithContacts, state)
  ),
  on(
    customersActions.loadCustomerAddressesSuccess,
    (state, { customerWithAddresses }): CustomersState =>
      customersAdapter.upsertOne(customerWithAddresses, state)
  ),
  on(
    customersActions.setTotalCount,
    (state, { count }): CustomersState => ({
      ...state,
      totalCount: count
    })
  ),
  on(
    customersActions.setLoading,
    (state, { operation, loading }): CustomersState => ({
      ...state,
      loading: { ...state.loading, [operation]: loading }
    })
  ),
  on(
    customersActions.resetListFilters,
    (state): CustomersState => ({
      ...state,
      contactFilters: initialCustomersState.contactFilters
    })
  ),
  on(
    customersActions.setContactListFilters,
    (state, { contactFilters }): CustomersState => ({
      ...state,
      contactFilters
    })
  ),
  on(
    customersActions.setMarkupsListFilters,
    (state, { markupsFilters }): CustomersState => ({
      ...state,
      markupsFilters
    })
  ),
  on(
    customersActions.setNonRoutedFeesListFilters,
    (state, { nonRoutedFeesFilters }): CustomersState => ({
      ...state,
      nonRoutedFeesFilters
    })
  ),
  on(
    customersActions.setAddessesListFilters,
    (state, { addressesFilters }): CustomersState => ({
      ...state,
      addressesFilters
    })
  ),
  on(
    customersActions.setListFilters,
    (state, { filters }): CustomersState => ({
      ...state,
      filters
    })
  ),
  on(
    customersActions.loadCustomerAddressTypesSuccess,
    (state, { addressTypes }): CustomersState => ({
      ...state,
      addressTypes
    })
  ),
  on(
    customersActions.updateCustomerAddressActiveStatusSuccess,
    (state, { customerWithUpdatedAddress }): CustomersState =>
      customersAdapter.upsertOne(customerWithUpdatedAddress as Customer, state)
  ),
  on(
    customersActions.loadCustomerFeesSuccess,
    (state, { fees, customerId }): CustomersState =>
      customersAdapter.upsertOne({ id: customerId, fees } as Customer, state)
  ),
  on(
    customersActions.resetAddessesListFilters,
    (state): CustomersState => ({
      ...state,
      addressesFilters: initialCustomersState.addressesFilters
    })
  ),
  on(
    customersActions.resetContactListFilters,
    (state): CustomersState => ({
      ...state,
      contactFilters: initialCustomersState.contactFilters
    })
  ),
  on(
    customersActions.resetMarkupsListFilters,
    (state): CustomersState => ({
      ...state,
      markupsFilters: initialCustomersState.markupsFilters
    })
  ),
  on(
    customersActions.resetNonRoutedFeesListFilters,
    (state): CustomersState => ({
      ...state,
      nonRoutedFeesFilters: initialCustomersState.nonRoutedFeesFilters
    })
  ),
  on(
    customersActions.patchListFilters,
    (state, { filters }): CustomersState => ({
      ...state,
      filters: {
        ...state.filters,
        ...filters
      }
    })
  )
)

export function customersReducer(
  state: CustomersState | undefined,
  action: Action
) {
  return reducer(state, action)
}
