import { IStrategy } from '@navix/utils/adapter'
import { Customer } from '../domain/customer.model'
import { GetCustomerContactsResponse } from '../domain/get-customer-contacts.response'

export class FromGetCustomerContactsResponse
  implements IStrategy<GetCustomerContactsResponse, Customer>
{
  convert(response: GetCustomerContactsResponse): Customer {
    return {
      contacts: response.value.map(
        contact =>
          <Customer['contacts'][number]>{
            departmentId: contact.Department,
            email: contact.ContactEmail,
            isPrimary: contact.IsPrimary,
            name: contact.ContactName,
            phone: contact.ContactPhone,
            id: contact.CustomerContactId
          }
      )
    } as Customer
  }
}
