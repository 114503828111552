import { IStrategy } from '@navix/utils/adapter'

import { GetCustomerFeesResponse } from '../domain/get-customer-fees.response'
import { CustomerFee } from '../domain/customer-fee.model'

export class FromGetCustomerFeesResponse
  implements IStrategy<GetCustomerFeesResponse, CustomerFee[]>
{
  convert(response: GetCustomerFeesResponse): CustomerFee[] {
    return response.value.map(
      customerFee =>
        <CustomerFee>{
          id: customerFee.CustomerFeeId,
          transportationServiceTypeDescription:
            customerFee.TransportationServiceTypeDescription ?? 'Default',
          transportationServiceTypeId: customerFee.TransportationServiceTypeId,
          markup: customerFee.Markup,
          maximum: customerFee.Maximum,
          minimum: customerFee.Minimum,
          startDate: new Date(customerFee.StartDate),
          endDate: customerFee.EndDate
            ? new Date(customerFee.EndDate)
            : undefined,
          isNonRouted: customerFee.IsNonRouted
        }
    )
  }
}
