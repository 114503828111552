import { Injectable, inject } from '@angular/core'
import { FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms'
import {
  ControlsOf,
  CustomerForm,
  CustomerFormControls
} from '../domain/customer-form.model'
import { NavixValidators } from '@navix/forms'

@Injectable({ providedIn: 'root' })
export class CustomerFormService {
  private readonly fb = inject(NonNullableFormBuilder)

  getCustomerForm() {
    return this.fb.group<
      Omit<CustomerFormControls, 'id' | 'fee' | 'accountNumbers'>
    >({
      details: this.getDetailsForm(),
      address: this.getHeadquartersForm(),
      contact: this.getContactForm()
    })
  }
  getAccountNumbersFormWithDefaultGroup(): CustomerFormControls['accountNumbers'] {
    return this.fb.array<FormGroup>([this.getAccountNumberForm()])
  }

  getDetailsForm(): CustomerFormControls['details'] {
    return this.fb.group(
      {
        name: this.fb.control('', Validators.required),
        currencyId: this.fb.control<number | undefined>(
          undefined,
          Validators.required
        ),
        billingTermId: this.fb.control<number | undefined>(
          undefined,
          Validators.required
        ),
        externalId: this.fb.control('', Validators.required),

        startDate: this.fb.control<Date | undefined>(undefined),
        endDate: this.fb.control<Date | undefined>(undefined),
        hasSmc3: this.fb.control<boolean>(false)
      },
      {
        validators: [NavixValidators.requiredIf('startDate', 'endDate')]
      }
    )
  }

  getHeadquartersForm(): CustomerFormControls['address'] {
    return this.fb.group({
      name: this.fb.control(''),
      address1: this.fb.control(''),
      address2: this.fb.control<string>(''),
      countryCode: this.fb.control<string | undefined>(undefined),
      city: this.fb.control(''),
      stateCode: this.fb.control<string | undefined>(undefined),
      zip: this.fb.control(''),
      isPrimary: this.fb.control<boolean | undefined>(undefined),
      typeId: this.fb.control<number | undefined>(undefined),
      id: this.fb.control<number | undefined>(undefined),
      codes: this.fb.array<FormGroup>([])
    })
  }

  getAddressForm(): CustomerFormControls['address'] {
    return this.fb.group({
      name: this.fb.control('', Validators.required),
      address1: this.fb.control('', Validators.required),
      address2: this.fb.control<string>(''),
      countryCode: this.fb.control<string | undefined>(
        undefined,
        Validators.required
      ),
      city: this.fb.control('', Validators.required),
      stateCode: this.fb.control<string | undefined>(
        undefined,
        Validators.required
      ),
      zip: this.fb.control('', Validators.required),
      isPrimary: this.fb.control<boolean | undefined>(
        undefined,
        Validators.required
      ),
      typeId: this.fb.control<number | undefined>(
        undefined,
        Validators.required
      ),
      id: this.fb.control<number | undefined>(undefined),
      codes: this.fb.array<FormGroup>([])
    })
  }

  getContactForm(): CustomerFormControls['contact'] {
    return this.fb.group({
      name: this.fb.control<string>(''),
      department: this.fb.control<string | undefined>(undefined),
      email: this.fb.control<string>(''),
      phone: this.fb.control<string>(''),
      isPrimary: this.fb.control<boolean>(false),
      id: this.fb.control<number | undefined>(undefined)
    })
  }

  getFeeForm(): CustomerFormControls['fee'] {
    return this.fb.group({
      serviceTypeId: this.fb.control<number | undefined>(
        undefined,
        Validators.required
      ),
      markup: this.fb.group(
        {
          percentage: this.fb.control<number | undefined>(undefined, [
            Validators.required,
            Validators.min(0),
            Validators.max(100)
          ]),

          minimum: this.fb.control<number | undefined>(undefined, [
            Validators.required,
            Validators.min(0)
          ]),
          maximum: this.fb.control<number | undefined>(undefined, [
            Validators.min(0)
          ])
        },
        {
          validators: [
            NavixValidators.lowerThan('minimum', 'maximum'),
            NavixValidators.greaterThan('maximum', 'minimum')
          ]
        }
      ),
      dateRange: this.fb.group(
        {
          startDate: this.fb.control<string | Date>('', Validators.required),
          endDate: this.fb.control<string | Date | undefined>(undefined)
        },
        {
          validators: [
            NavixValidators.dateLowerThan('startDate', 'endDate', 'end date'),
            NavixValidators.dateGreaterThan(
              'endDate',
              'startDate',
              'start date'
            )
          ]
        }
      ),
      isDefault: this.fb.control<boolean>(false),
      isNonRouted: this.fb.control<boolean>(false),
      id: this.fb.control<number | undefined>(undefined)
    })
  }

  getAccountNumberForm(
    accountNumber?: Partial<CustomerForm['accountNumbers'][number]>
  ) {
    const isPrimary = accountNumber
      ? Boolean(accountNumber?.isPrimary)
      : undefined
    return this.fb.group({
      isPrimary: this.fb.control<boolean | undefined>(
        isPrimary,
        Validators.required
      ),
      id: accountNumber?.id ?? 0,
      name: this.fb.control(accountNumber?.name ?? '', Validators.required)
    })
  }

  getAddressCodeForm(
    addressCode?: Partial<CustomerForm['address']['codes'][number]>
  ): FormGroup<
    ControlsOf<{
      code: string
      isPrimary: boolean | undefined
      id: number
    }>
  > {
    const isPrimary = addressCode ? Boolean(addressCode?.isPrimary) : undefined
    return this.fb.group({
      isPrimary: this.fb.control<boolean | undefined>(
        isPrimary,
        Validators.required
      ),
      id: addressCode?.id ?? 0,
      code: this.fb.control(addressCode?.code ?? '', Validators.required)
    })
  }
}
