export enum AsyncOperations {
  getAll = 'getAll',
  add = 'add',
  getDetails = 'getDetails',
  getContacts = 'getContacts',
  getAddresses = 'getAddresses',
  updateCustomer = 'updateCustomer',
  updateCustomerContact = 'updateCustomerContact',
  updateCustomerAddress = 'updateCustomerAddress',
  updateCustomerAccountNumbers = 'updateCustomerAccountNumbers',
  addAddress = 'addAddress',
  addContact = 'addContact',
  addFee = 'addFee',
  updateFee = 'updateFee',
  deleteCustomerContact = 'deleteCustomerContact',
  getCustomerFees = 'getCustomerFees',
  updateRatingEngineInfo = 'updateRatingEngineInfo'
}

export type CustomersLoading = {
  [key in AsyncOperations]: boolean
}
