import { createFeatureSelector, createSelector } from '@ngrx/store'
import {
  CUSTOMERS_FEATURE_KEY,
  CustomersState,
  customersAdapter
} from './customers.reducer'

export const selectCustomersState = createFeatureSelector<CustomersState>(
  CUSTOMERS_FEATURE_KEY
)

const { selectAll, selectEntities } = customersAdapter.getSelectors()

export const selectAllCustomers = createSelector(
  selectCustomersState,
  (state: CustomersState) => selectAll(state)
)

export const selectCustomersEntities = createSelector(
  selectCustomersState,
  (state: CustomersState) => selectEntities(state)
)

export const selectSelectedId = createSelector(
  selectCustomersState,
  (state: CustomersState) => state.selectedId
)

export const selectTotalCount = createSelector(
  selectCustomersState,
  (state: CustomersState) => state.totalCount
)

export const selectLoading = createSelector(
  selectCustomersState,
  (state: CustomersState) => state.loading
)

export const selectEntity = createSelector(
  selectCustomersEntities,
  selectSelectedId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
)

export const selecFilters = createSelector(
  selectCustomersState,
  (state: CustomersState) => state.filters
)

export const selectAccountNumberStatuses = createSelector(
  selectCustomersState,
  (state: CustomersState) => state.accountNumberStatuses
)

export const selectContactListFilters = createSelector(
  selectCustomersState,
  (state: CustomersState) => state.contactFilters
)

export const selectAddressesListFilters = createSelector(
  selectCustomersState,
  (state: CustomersState) => state.addressesFilters
)

export const selectMarkupsListFilters = createSelector(
  selectCustomersState,
  (state: CustomersState) => state.markupsFilters
)

export const selectNonRoutedFeesListFilters = createSelector(
  selectCustomersState,
  (state: CustomersState) => state.nonRoutedFeesFilters
)

export const selectAddressTypes = createSelector(
  selectCustomersState,
  (state: CustomersState) => state.addressTypes
)
