import { IStrategy } from '@navix/utils/adapter'
import { AddCustomerContactRequest } from '../domain/add-customer-contact.request'
import { CustomerForm } from '../domain/customer-form.model'

export class ToAddCustomerContactRequest
  implements IStrategy<CustomerForm, AddCustomerContactRequest>
{
  convert(customer: CustomerForm): AddCustomerContactRequest {
    return {
      contactName: customer.contact.name,
      department: customer.contact.department,
      contactPhone: customer.contact.phone,
      contactEmail: customer.contact.email,
      isPrimary: false,
      customerId: customer.id
    }
  }
}
